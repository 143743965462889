import { z } from 'zod';


export const modifyCartSchema = z.object({
  lineId: z.string().optional(),
  variantId: z.string().optional(),
  quantity: z.number().optional(),
  options: z
    .array(z.object({ optionId: z.string(), optionLabel: z.string(), value: z.any() }))
    .default([]),
  slug: z.string(),
});

export function hasShipping(order: any) {
  return false;
}

export function hasPayment(order: any) {
  return false;
}

export function hasQuantityRelevance(line: any) {
  return false;
}

export function productHasPrice(product: any) {
  return false;
}

export async function setCartId(session: any, cartId: string) {
  session.set('CART_ID', cartId);
  return session;
}

export async function getCartId(session: any): Promise<string> {
  const cartId = session.get('CART_ID');

  return cartId;
}
